import React from 'react';
import { upperFirst } from 'lodash';
// Hooks
import { useEasyMealsQuery } from '../../hooks/useEasyMealsPageQuery';
import { getRecipeTitleAndAlias } from '../../libs/formatFromGraph';
// Components
import EastMealsHero from './easy-meals-hero';
import EasyMealsRecipeCard from './easy-meals-recipe-card';
import EasyMealsShoppingList from './easy-meals-shopping-list';
import FlyerFullsize from '../common/flyer-fullsize/flyer-fullsize';
// Assets
import styles from './easy-meals-container.module.scss';

const EasyMealsContainer = () => {
  const data = useEasyMealsQuery();
  const hero = data.hero;
  const flyer = data.cta;
  const shoppingList = {
    title: data.shoppingList.title,
    list: data.shoppingList.list.split('\n'),
  };
  const recipes = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'].map(day => {
    const days = data.days;
    const ref = getRecipeTitleAndAlias(days[`${day}RecipeRef`]);
    return {
      cmsImage: days[`${day}RecipeImage`],
      label: upperFirst(day),
      title: ref.title,
      text: days[`${day}RecipeDescription`],
      link: {
        title: 'View Recipe',
        uri: ref.alias,
      },
    };
  });

  return (
    <div className={styles.easyMealsContainerMain}>
      <EastMealsHero data={hero}/>
      <div className={styles.easyMealsContainerBody}>
        {recipes.map((recipe, index) => (
          <EasyMealsRecipeCard data={recipe} key={index} invert={index % 2 === 1}/>
        ))}
        <EasyMealsShoppingList data={shoppingList}/>
      </div>
      <FlyerFullsize content={flyer}/>
    </div>
  );
};

export default EasyMealsContainer;
