import React from 'react';
import ImageFeatureArea from '../common/image-feature-area/image-feature-area';
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';

const EasyMealsRecipeCard = ({ data, invert }) => {
  const image = useImageStaticQuery(data.image, data.cmsImage);

  return (
    <ImageFeatureArea data={data} imageFluid={image} invert={invert} background={'transparent'}/>
  );
};
export default EasyMealsRecipeCard;
