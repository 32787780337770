// Libs.
import React from 'react';
// Components.
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import EasyMealsContainer from '../components/easy-meals/easy-meals-container';

const CareersPage = () => {
  return (
    <Layout>
      <SEO title="5 Easy Meals" />
      <EasyMealsContainer />
    </Layout>
  );
};

export default CareersPage;
