import { useStaticQuery, graphql } from 'gatsby';
import {drupalTransformer} from '../libs/transform/index'

export const useEasyMealsQuery = () => {
  return drupalTransformer.transformNode(useEasyMeals());
};

const useEasyMeals = () => {
  return useStaticQuery(graphql`
    query useEasyMealsQuery {
      shoppingList: configPagesEasyMeals {
        title: field_shopping_list_title
        list: field_shopping_list
      }
      hero: configPagesEasyMeals {
        body: field_hero_description {
          value
        }
        title: field_hero_title {
          value
        }
        relationships {
          image: field_hero_image {
            localFile {
              childImageSharp {
                fluid {
                  srcSet
                  src
                  base64
                  aspectRatio
                  sizes
                }
              }
            }
          }
        }
      }
      cta: configPagesEasyMeals {
        text: field_cta_description
        link: field_cta_link {
          title
          uri
        }
        field_label
        title: field_cta_title
        relationships {
          cmsImage: field_cta_image {
            localFile {
              childImageSharp {
                fluid {
                  srcSet
                  src
                  base64
                  aspectRatio
                  sizes
                }
              }
            }
          }
        }
      }
      days: configPagesEasyMeals {
        fridayRecipeDescription: field_friday_recipe_description
        mondayRecipeDescription: field_monday_recipe_description
        thursdayRecipeDescription: field_thursday_recipe_descriptio
        tuesdayRecipeDescription: field_tuesday_recipe_description
        wednesdayRecipeDescription: field_wednesday_recipe_descripti
        relationships {
          field_monday_recipe_ref {
              title
          }
          field_tuesday_recipe_ref {
              title
          }
          field_wednesday_recipe_ref {
              title
          }
          field_thursday_recipe_ref {
              title
          }
          field_friday_recipe_ref {
              title
          }
          field_monday_recipe_image {
            localFile {
              childImageSharp {
                fluid {
                  srcSet
                  src
                  base64
                  aspectRatio
                  sizes
                }
              }
            }
          }
          field_tuesday_recipe_image {
            localFile {
              childImageSharp {
                fluid {
                  srcSet
                  src
                  base64
                  aspectRatio
                  sizes
                }
              }
            }
          }
          field_wednesday_recipe_image {
            localFile {
              childImageSharp {
                fluid {
                  srcSet
                  src
                  base64
                  aspectRatio
                  sizes
                }
              }
            }
          }
          field_thursday_recipe_image {
            localFile {
              childImageSharp {
                fluid {
                  srcSet
                  src
                  base64
                  aspectRatio
                  sizes
                }
              }
            }
          }
          field_friday_recipe_image {
            localFile {
              childImageSharp {
                fluid {
                  srcSet
                  src
                  base64
                  aspectRatio
                  sizes
                }
              }
            }
          }
        }
      }
    }
  `);
};
