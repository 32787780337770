import React from 'react';

import styles from './easy-meals-shopping-list.module.scss';

const EasyMealsShoppingList = ({ data }) => {
  return (
    <div>
      <div className={styles.easyMealsShoppingListTitle}>{data.title}</div>
      <div className={styles.easyMealsShoppingListItemsContainer}>
        {data.list.map((item, index) => (
          <div className={styles.easyMealsShoppingListItem} key={index}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};
export default EasyMealsShoppingList;
